// 赛事子分支
export const competitionRoutes = [
  {
    path: '/chineseEightBallActivityPayRecord',
    name: 'chineseEightBallActivityPayRecord',
    meta: {
      title: "中式八球比赛缴费记录",
      key: 'chinese-eight-ball-activity-pay-record',
      pageApisPrm: import("../views/operationManage/chineseEightBallActivityPayRecord.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () => import(
      /* webpackChunkName: "operationManage" */ "../views/operationManage/chineseEightBallActivityPayRecord.vue"
      ),
    },
  {
    // leagueCourserList
    path: '/leagueCourserList',
    name: 'leagueCourserList',
    meta: {
      title: "参赛选手",
      key: 'league-courser-ist',
      pageApisPrm: import("../views/operationManage/leagueCourserList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () => import(
      /* webpackChunkName: "operationManage" */ "../views/operationManage/leagueCourserList.vue"
    ),
  },

  // leagueCourserDetailList
  {
    path: '/leagueCourserDetailList',
    name: 'leagueCourserDetailList',
    meta: {
      title: "选手比赛详情列表",
      key: 'league-courser-detail-list',
      asideHidden: true, // 侧边栏不显示
      pageApisPrm: import("../views/operationManage/leagueCourserDetailList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () => import(
      /* webpackChunkName: "operationManage" */ "../views/operationManage/leagueCourserDetailList.vue"
    ),
  },
  {
    path: '/commercialCollege',
    name: 'commercialCollege',
    meta: {
      title: "商学院",
      key: 'commercial-college-list',
      pageApisPrm: import("../views/operationManage/commercialCollege.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () => import(
      /* webpackChunkName: "operationManage" */ "../views/operationManage/commercialCollege.vue"
    ),
  },
]


// 夏日活动
export const summerActRewarsRoutes = [
  {
    path: '/summerActRewardPoolList',
    name: 'summerActRewardPoolList',
    meta: {
      title: "暑假奖池设置",
      key: 'summer-act-reward-pool-list',
      pageApisPrm: import("../views/operationManage/summerActRewardPoolList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "operationManage" */ "../views/operationManage/summerActRewardPoolList.vue"
      ),
  },
  {
    path: '/summerRewardRecord',
    name: 'summerRewardRecord',
    meta: {
      title: "暑假中奖记录",
      key: 'summer-reward-record',
      pageApisPrm: import("../views/operationManage/summerRewardRecord.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () => import(
        /* webpackChunkName: "operationManage" */ "../views/operationManage/summerRewardRecord.vue"
        ),
  },
  {
    path: '/summerActivityHorse',
    name: 'summerActivityHorse',
    meta: {
      title: "暑假活动跑马灯",
      key: 'summer-activity-horse',
      pageApisPrm: import("../views/operationManage/summerActivityHorse.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () => import(
        /* webpackChunkName: "operationManage" */ "../views/operationManage/summerActivityHorse.vue"
        ),
  },
  {
    path: '/summerActivityShieldStore',
    name: 'summerActivityShieldStore',
    meta: {
      title: "暑假活动屏蔽店铺",
      key: 'summer-activity-shield-store',
      pageApisPrm: import("../views/operationManage/summerActivityShieldStore.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () => import(
        /* webpackChunkName: "operationManage" */ "../views/operationManage/summerActivityShieldStore.vue"
        ),
  },
]

// 限时活动 子分支
export const limitTimeActiviesRoutes = [
  ...summerActRewarsRoutes,
  {
    path: '/pandaFanGroupQrcode',
    name: 'pandaFanGroupQrcode',
    meta: {
      title: "熊猫粉丝群二维码",
      key: 'panda-fan-group-qrcode',
      pageApisPrm: import("../views/operationManage/pandaFanGroupQrcode.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "operationManage" */ "../views/operationManage/pandaFanGroupQrcode.vue"
      ),
  }
]

// 榜单 子分支
export const rankingOptionsRoutes = [
  {
    path: '/openTimeLevelRewardSetting',
    name: 'openTimeLevelRewardSetting',
    meta: {
      title: "段位榜奖励设置",
      key: 'open-time-level-reward-setting',
      pageApisPrm: import("../views/operationManage/openTimeLevelRewardSetting.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "operationManage" */ "../views/operationManage/openTimeLevelRewardSetting.vue"
      ),
  },
  {
    path: '/openTimeLevelRewardRecord',
    name: 'openTimeLevelRewardRecord',
    meta: {
      title: "段位榜奖励记录",
      key: 'open-time-level-reward-record',
      pageApisPrm: import("../views/operationManage/openTimeLevelRewardRecord.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "operationManage" */ "../views/operationManage/openTimeLevelRewardRecord.vue"
      ),
  },
  {
    path: '/competitionPointBlackUserList',
    name: 'competitionPointBlackUserList',
    meta: {
      title: "段位榜黑名单",
      key: 'competition-point-black-user-list',
      pageApisPrm: import("../views/operationManage/competitionPointBlackUserList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "operationManage" */ "../views/operationManage/competitionPointBlackUserList.vue"
      ),
  },
  // competitionPointBlackUserList
]

// 助教子分支
export const coachOptionsRoutes = [
  // coachLeaderManage
  {
    path: '/coachLeaderManage',
    name: 'coachLeaderManage',
    meta: {
      title: "助教队长管理",
      key: 'coach-leader-manage',
      pageApisPrm: import("../views/operationManage/coachLeaderManage.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "operationManage" */ "../views/operationManage/coachLeaderManage.vue"
      ),
  },
  // coachLeaderSubordinateList
  {
    path: '/coachLeaderSubordinateList',
    name: 'coachLeaderSubordinateList',
    meta: {
      title: "助教队长成员列表",
      asideHidden: true, // 侧边栏不显示
      key: 'coach-leader-subordinate-list',
      pageApisPrm: import("../views/operationManage/coachLeaderSubordinateList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "operationManage" */ "../views/operationManage/coachLeaderSubordinateList.vue"
      ),
  },
  {
    path: '/coachProportionSetting',
    name: 'coachProportionSetting',
    meta: {
      title: "助教分成配置",
      key: 'coach-proportion-setting',
      pageApisPrm: import("../views/operationManage/coachProportionSetting.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "operationManage" */ "../views/operationManage/coachProportionSetting.vue"
      ),
  },
  {
    path: '/coachBanList',
    name: 'coachBanList',
    meta: {
      title: "助教封禁列表",
      key: 'coach-ban-list',
      pageApisPrm: import("@/views/operationManage/coachBanList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () => import(/* webpackChunkName: "operationManage" */ "@/views/operationManage/coachBanList.vue"),
  },
]


// 运营合伙人 子分支
export const recommanderPartnerRoutes = [
  {
    path: '/recommanderManage',
    name: 'recommanderManage',
    meta: {
      title: "推广人管理",
      key: 'recommander-manage',
      pageApisPrm: import("../views/operationManage/recommanderManage.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "operationManage" */ "../views/operationManage/recommanderManage.vue"
      ),
  },
  {
    path: '/recommanderDetailList',
    name: 'recommanderDetailList',
    meta: {
      title: "推广人详情",
      asideHidden: true, // 侧边栏不显示
      key: 'recommander-detail-list',
      pageApisPrm: import("../views/operationManage/recommanderDetailList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "operationManage" */ "../views/operationManage/recommanderDetailList.vue"
      ),
  },
]

// 售卖机 子分支
export const saleMachineRoutes = [
  {
    path: '/saleMachineList',
    name: 'saleMachineList',
    meta: {
      title: "售卖机管理",
      key: 'sale-mechine-list',
      pageApisPrm: import("../views/operationManage/saleMachineList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "operationManage" */ "../views/operationManage/saleMachineList.vue"
      ),
  },
  // saleMachineBlackUserList
  {
    path: '/saleMachineBlackUserList',
    name: 'saleMachineBlackUserList',
    meta: {
      title: "售卖机黑名单",
      key: 'sale-machine-black-user-list',
      pageApisPrm: import("../views/operationManage/saleMachineBlackUserList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "operationManage" */ "../views/operationManage/saleMachineBlackUserList.vue"
      ),
  }
]


// 会员和钻石 子分支
export const couponAndmemberAndDiamondRoutes = [

  {
    path: '/userCouponList',
    name: 'userCouponList',
    meta: {
      title: "优惠券管理",
      key: 'user-coupon-list',
      pageApisPrm: import("../views/operationManage/userCouponList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "operationManage" */ "../views/operationManage/userCouponList.vue"
      ),
  },

  {
    // singleCardAccountList
    path: '/singleCardAccountList',
    name: 'singleCardAccountList',
    meta: {
      title: "单店卡余额管理",
      key: 'single-card-account-list',
      pageApisPrm: import("../views/operationManage/singleCardAccountList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "operationManage" */ "../views/operationManage/singleCardAccountList.vue"
      ),
  },
  {
    // singleCardChargeList
    path: '/singleCardChargeList',
    name: 'singleCardChargeList',
    meta: {
      title: "单店卡充值记录",
      key: 'single-card-charge-list',
      pageApisPrm: import("../views/operationManage/singleCardChargeList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "operationManage" */ "../views/operationManage/singleCardChargeList.vue"
      ),
  },
  // memberCouponUsedRecord
  {
    path: '/memberCouponUsedRecord',
    name: 'memberCouponUsedRecord',
    meta: {
      title: "电子券使用记录",
      key: 'member-coupon-used-record',
      pageApisPrm: import("../views/operationManage/memberCouponUsedRecord.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "operationManage" */ "../views/operationManage/memberCouponUsedRecord.vue"
      ),
  },
  {
    path: "/memberCharge",
    name: "memberCharge",
    meta: {
      title: "会员充值",
      key: 'member-charge-list',
      pageApisPrm: import("../views/operationManage/memberCharge.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(/* webpackChunkName: "operationManage" */ "../views/operationManage/memberCharge.vue"),
  },
  {
    path: "/memberStatistic",
    name: "memberStatistic",
    meta: {
      title: "会员统计",
      key: 'member-statistic-list',
      pageApisPrm: import("../views/operationManage/memberStatistic.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(/* webpackChunkName: "operationManage" */ "../views/operationManage/memberStatistic.vue"),
  },
  {
    path: '/memberList',
    name: 'memberList',
    meta: {
      title: "会员管理",
      key: 'member-user-list',
      pageApisPrm: import("../views/operationManage/memberList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "operationManage" */ "../views/operationManage/memberList.vue"
      ),
  },
  {
    path: '/memberConsumeRecord',
    name: 'memberConsumeRecord',
    meta: {
      title: "会员消费记录",
      key: 'member-consume-record',
      pageApisPrm: import("../views/operationManage/memberConsumeRecord.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "operationManage" */ "../views/operationManage/memberConsumeRecord.vue"
      ),
  },
  {
    path: "/memberOpenRecord",
    name: "memberOpenRecord",
    meta: {
      title: "会员开通记录",
      key: 'member-open-record',
      pageApisPrm: import("../views/operationManage/memberOpenRecord.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "operationManage" */ "../views/operationManage/memberOpenRecord.vue"
      ),
  },
  {
    path: '/diamondTakeRecord',
    name: "diamondTakeRecord",
    meta: {
      title: "钻石获取记录",
      key: 'diamond-take-record',
      pageApisPrm: import("../views/operationManage/diamondTakeRecord.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "operationManage" */ "../views/operationManage/diamondTakeRecord.vue"
      ),
  },
  {
    path: '/diamondExchangeRecord',
    name: "diamondExchangeRecord",
    meta: {
      title: "钻石兑换记录",
      key: 'diamond-exchange-record',
      pageApisPrm: import("../views/operationManage/diamondExchangeRecord.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "operationManage" */ "../views/operationManage/diamondExchangeRecord.vue"
      ),
  },
]


  // 子页面 ！！！ 服务评价 ！！！ 相关页面
const venueServiceEvaluateRoutes = [
  {
    path: '/unEvaluateMerchantList',
    name: "unEvaluateMerchantList",
    meta: {
      title: "未提交评分店铺",
      key: 'un-evaluate-merchant-list',
      pageApisPrm: import("../views/operationManage/unEvaluateMerchantList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "operationManage" */ "../views/operationManage/unEvaluateMerchantList.vue"
      ),
  },
  {
    path: '/operationsPersonnel',
    name: "operationsPersonnel",
    meta: {
      title: "运营人员",
      key: 'operations-personnel',
      pageApisPrm: import("../views/operationManage/operationsPersonnel.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
        import(
            /* webpackChunkName: "operationManage" */ "../views/operationManage/operationsPersonnel.vue"
            ),
  },
  {
    path: '/operationalServiceRating',
    name: "operationalServiceRating",
    meta: {
      title: "运营服务评分",
      key: 'operational-service-rating',
      pageApisPrm: import("../views/operationManage/operationalServiceRating.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
        import(
            /* webpackChunkName: "operationManage" */ "../views/operationManage/operationalServiceRating.vue"
            ),
  }
]

// 店铺配置
export const venueSettingRoutes = [
  {
    path: '/smsSendRecord',
    name: 'smsSendRecord',
    meta: {
      title: "短信发送记录",
      key: 'sms-send-record',
      pageApisPrm: import("../views/operationManage/smsSendRecord.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "operationManage" */ "../views/operationManage/smsSendRecord.vue"
      ),
  },
  {
    path: "/openCities",
    name: "openCities",
    meta: {
      title: "开通城市",
      key: 'open-cities',
      pageApisPrm: import("../views/operationManage/openCities.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "operationManage" */ "../views/operationManage/openCities.vue"
      ),
  },
  {
    path: '/bannerList',
    name: 'bannerList',
    meta: {
      title: "轮播图管理",
      key: 'bannar-list',
      pageApisPrm: import("../views/operationManage/bannerList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "operationManage" */ "../views/operationManage/bannerList.vue"
      ),
  },
  // allShopQRCode
  {
    path: '/allShopQRCode',
    name: 'allShopQRCode',
    meta: {
      title: "店铺二维码",
      key: 'all-shop-qrcode',
      pageApisPrm: import("../views/operationManage/allShopQRCode.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "operationManage" */ "../views/operationManage/allShopQRCode.vue"
      ),
  },
  {
    path: '/ballCoachQRCode',
    name: 'ballCoachQRCode',
    meta: {
      title: "球社助教二维码",
      key: 'ball-coach-qrcode',
      pageApisPrm: import("../views/operationManage/ballCoachQRCode.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "operationManage" */ "../views/operationManage/ballCoachQRCode.vue"
      ),
  },
  {
    path: '/gloryShopQRCode',
    name: 'gloryShopQRCode',
    meta: {
      title: "棋牌门店二维码",
      key: 'glory-shop-qrcode',
      pageApisPrm: import("../views/operationManage/gloryShopQRCode.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "operationManage" */ "../views/operationManage/gloryShopQRCode.vue"
      ),
  },
  {
    path: '/shopStorageLocker',
    name: 'shopStorageLocker',
    meta: {
      title: "球杆柜管理",
      key: 'shop-storage-locker',
      pageApisPrm: import("../views/operationManage/shopStorageLocker.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "operationManage" */ "../views/operationManage/shopStorageLocker.vue"
      ),
  },
  {
    path: '/lockerDoorList',
    name: 'lockerDoorList',
    meta: {
      title: "球杆柜门列表",
      asideHidden: true, // 侧边栏不显示
      key: 'locker-door-list',
      pageApisPrm: import("../views/operationManage/lockerDoorList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "operationManage" */ "../views/operationManage/lockerDoorList.vue"
      ),
  },

  {
    path: "/poolCategory",
    name: "poolCategory",
    meta: {
      title: "球桌/包厢分类",
      key: 'pool-table-cage-category',
      pageApisPrm: import("../views/operationManage/poolCategory.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "operationManage" */ "../views/operationManage/poolCategory.vue"
      ),
  },
  {
    path: "/shopAddress",
    name: "shopAddress",
    meta: {
      title: "店铺地址",
      key: 'shop-address',
      pageApisPrm: import("../views/operationManage/shopAddress.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
        import(
            /* webpackChunkName: "operationManage" */ "../views/operationManage/shopAddress.vue"
            ),
  },
  {
    path: "/afterSales",
    name: "afterSales",
    meta: {
      title: "售后审核",
      key: 'after-sales',
      pageApisPrm: import("../views/operationManage/afterSales.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
        import(
            /* webpackChunkName: "operationManage" */ "../views/operationManage/afterSales.vue"
            ),
  },
  {
    path: "/businessLimitWarning",
    name: "businessLimitWarning",
    meta: {
      title: "营业额预警",
      key: 'business-limit-warning',
      pageApisPrm: import("../views/operationManage/businessLimitWarning.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
        import(
            /* webpackChunkName: "operationManage" */ "../views/operationManage/businessLimitWarning.vue"
            ),
  },
  ...venueServiceEvaluateRoutes
]

// 用户信息
export const socialManageRoutes = [
  {
    // vantPointLoanRecord
    path: "/vantPointLoanRecord",
    name: "vantPointLoanRecord",
    meta: {
      title: "有赞贷款积分",
      key: 'vant-point-load-record',
      pageApisPrm: import("../views/operationManage/vantPointLoanRecord.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
        import(
            /* webpackChunkName: "operationManage" */ "../views/operationManage/vantPointLoanRecord.vue"
        ),
  },
  {
    path: "/vantPointChangeRecord",
    name: "vantPointChangeRecord",
    meta: {
      title: "有赞商城积分管理",
      key: 'vant-point-change-record',
      pageApisPrm: import("../views/operationManage/vantPointChangeRecord.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
        import(
            /* webpackChunkName: "operationManage" */ "../views/operationManage/vantPointChangeRecord.vue"
        ),
  },
  {
    // vantBindMerchantList
    path: "/vantBindMerchantList",
    name: "vantBindMerchantList",
    meta: {
      title: "有赞商城绑定商户",
      key: 'vant-bind-merchant-list',
      pageApisPrm: import("../views/operationManage/vantBindMerchantList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
        import(
            /* webpackChunkName: "operationManage" */ "../views/operationManage/vantBindMerchantList.vue"
        ),
  },
  {
    path: "/pandaCoinManage",
    name: "pandaCoinManage",
    meta: {
      title: "熊猫币管理",
      key: 'panda-coin-manage',
      pageApisPrm: import("../views/operationManage/pandaCoinManage.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
        import(
            /* webpackChunkName: "operationManage" */ "../views/operationManage/pandaCoinManage.vue"
        ),
  },
  {
    path: "/pandaCoinRecord",
    name: "pandaCoinRecord",
    meta: {
      title: "熊猫币充值记录",
      key: 'panda-coin-record',
      pageApisPrm: import("../views/operationManage/pandaCoinRecord.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
        import(
            /* webpackChunkName: "operationManage" */ "../views/operationManage/pandaCoinRecord.vue"
            ),
  },
  {
    path: "/reportExamine",
    name: "reportExamine",
    meta: {
      title: "举报审核",
      key: 'report-examine',
      pageApisPrm: import("../views/operationManage/reportExamine.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
        import(
            /* webpackChunkName: "operationManage" */ "../views/operationManage/reportExamine.vue"
            ),
  },
  {
    path: "/dynamicReportExamine",
    name: "dynamicReportExamine",
    meta: {
      title: "动态举报审核",
      key: 'dynamic-report-examine',
      pageApisPrm: import("../views/operationManage/dynamicReportExamine.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
        import(
            /* webpackChunkName: "operationManage" */ "../views/operationManage/dynamicReportExamine.vue"
            ),
  },
  {
    path: "/dynamicManage",
    name: "dynamicManage",
    meta: {
      title: "动态管理",
      key: 'dynamic-manage',
      pageApisPrm: import("../views/operationManage/dynamicManage.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
        import(
            /* webpackChunkName: "operationManage" */ "../views/operationManage/dynamicManage.vue"
            ),
  },
]


//熊猫球社和麦力士官网
export const websiteOperateManageRoutes = [
  {
    path: "/pandaWebsiteDynamicManage",
    name: "pandaWebsiteDynamicManage",
    meta: {
      title: "熊猫球社官网动态管理",
      key: 'panda-website-dynamic-manage',
      pageApisPrm: import("../views/operationManage/pandaWebsiteDynamicManage.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
        import(
            /* webpackChunkName: "operationManage" */ "../views/operationManage/pandaWebsiteDynamicManage.vue"
            ),
  },
  {
    path: "/molisWebsiteDynamicManage",
    name: "molisWebsiteDynamicManage",
    meta: {
      title: "麦力士社官网动态管理",
      key: 'molis-website-dynamic-manage',
      pageApisPrm: import("../views/operationManage/molisWebsiteDynamicManage.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
        import(
            /* webpackChunkName: "operationManage" */ "../views/operationManage/molisWebsiteDynamicManage.vue"
            ),
  },
]

//社交
export const chatManageRoutes = [
  {
    path: "/chatBanList",
    name: "chatBanList",
    meta: {
      title: "封禁列表",
      key: 'chat-ban-list',
      pageApisPrm: import("../views/operationManage/chatBanList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
        import(
            /* webpackChunkName: "operationManage" */ "../views/operationManage/chatBanList.vue"
            ),
  },
  {
    path: "/chatRecordQuery",
    name: "chatRecordQuery",
    meta: {
      title: "聊天记录",
      key: 'chat-record-query',
      pageApisPrm: import("../views/operationManage/chatRecordQuery.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
        import(
            /* webpackChunkName: "operationManage" */ "../views/operationManage/chatRecordQuery.vue"
            ),
  },
]

